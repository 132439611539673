import React from "react";
import { Card, Col, Row } from "react-bootstrap";

interface Props {
  title: string;
}

export class PSHeader extends React.PureComponent<Props> {
  render() {
    return (
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{this.props.title}</h5>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">{this.props.children}</div>
          </Col>
        </Row>
      </Card.Header>
    );
  }
}