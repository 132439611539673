import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { IRootState } from "../PSolutions.State";

interface Props {
  role: string;
  children: any;
}

export const RouteGuard = React.memo(({role, children}: Props) => {
  let location = useLocation();
  const roles = useSelector((state: IRootState) => state.app.roles);
  return roles.includes(role) ? children : <Navigate to="/" state={{from: location}}/>
})