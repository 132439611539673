import React from "react";
import { Card } from "react-bootstrap";

interface Props {
}

export class PSFooter extends React.PureComponent<Props> {
  render() {
    return (
      <Card.Footer>
        {this.props.children}
      </Card.Footer>
    );
  }
}