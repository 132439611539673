import React from "react";
import classNames from "classnames";

interface Props {
  bg: string;
  pill: boolean;
  className?: string;
}

export class SoftBadge extends React.PureComponent<Props> {
  static defaultProps = {pill: true, bg: "primary"};

  render() {
    const {bg, className, pill} = this.props;
    return (
      <div className={classNames(className, `badge badge-soft-${bg}`, {'rounded-pill': pill})}>{this.props.children}</div>
    );
  }
}