import cn from 'classnames';
import React, { forwardRef } from "react";
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from '../Styles/custom.date.module.css';

export const CustomDateInput = forwardRef(({value, onClick, onChange}: any, ref: any) => {
  return (
    <InputGroup size="sm" className="me-2">
      <Form.Control size="sm" ref={ref} onClick={onClick} value={value} onChange={onChange} className={cn('ps-3', styles.customDateInput)} placeholder="Odaberite raspon"/>
      <InputGroup.Text>
        <FontAwesomeIcon icon="calendar-alt" className="fs--1"/>
      </InputGroup.Text>
    </InputGroup>
  )
});