import React from "react";
import { FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IProduction } from "../Types";
import { IRootState } from "../../../PSolutions.State";
import { deleteEmployee } from "../../Employees/Redux/Actions";
import { PSFormRow } from "../../../PSolutions.UI/Form/PSFormRow";
import { PSFormCard } from "../../../PSolutions.UI/Form/PSFormCard";
import { InputField } from "../../../PSolutions.UI/Fields/InputField";
import { PSFormHolder } from "../../../PSolutions.UI/Form/PSFormHolder";
import { PSFormFooter } from "../../../PSolutions.UI/Form/PSFormFooter";
import { TextAreaField } from "../../../PSolutions.UI/Fields/TextAreaField";

interface Props extends FormikProps<IProduction> {

}

export const ProductionUpsertView = React.memo(({submitForm}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBackClick = () => navigate(-1)
  const {isEdit, selectedItem, isDeleting} = useSelector((state: IRootState) => state.production);

  async function deleteAsync() {
    await dispatch(deleteEmployee(selectedItem.id))
    handleBackClick();
  }

  return (
    <PSFormHolder>
      <PSFormCard title="Proizvodnja" columns={8}>
        <PSFormRow>
          <InputField
            id="quantity"
            name="quantity"
            type="number"
            colNum={6}
            required={true}
            label="Proizvedena količina u tonama"
            placeholder="Unesite količinu"
          />
          <InputField
            colNum={6}
            type="date"
            required={true}
            id="date"
            name="date"
            label="Datum"
            placeholder="Enter external identifier"
          />
        </PSFormRow>
        <PSFormRow>
          <TextAreaField
            rows={5}
            label="Opis"
            required={false}
            id="description"
            name="description"
            placeholder="Unesite opis"
          />
        </PSFormRow>
        <PSFormFooter>
          <Button variant="falcon-outline" className="me-2" onClick={handleBackClick}>Nazad</Button>
          <Button variant="primary" type="button" onClick={submitForm}>Spremi</Button>
        </PSFormFooter>
      </PSFormCard>
      <PSFormCard title="Opasna zona" columns={4}>
        <h5 className="mb-0">Obriši ovu stavku</h5>
        <p className="fs--1">Nakon što izbrišete stavku, nema povratka. Budite sigurni.</p>
        <Button variant="falcon-danger" disabled={!isEdit} className="w-100" onClick={deleteAsync}> {isDeleting ? <Spinner animation="border" size="sm"/> : "Obriši"}</Button>
      </PSFormCard>
    </PSFormHolder>
  );
});