import React from "react";
import _uniqueId from 'lodash/uniqueId';
import { Card, Spinner, Table } from "react-bootstrap";
import Scroller from "react-infinite-scroll-component";

interface Props {
  length: number;
  hasMore: boolean;

  loadMoreAsync(): void;
}

export class PSTableScroll extends React.PureComponent<Props> {
  private readonly id: string;

  constructor(props: Props) {
    super(props);
    this.id = _uniqueId("list");
  }

  render() {
    const {loadMoreAsync, hasMore, length} = this.props;
    return (
      <Card.Body className="p-0 table-full-height" id={this.id}>
        <Scroller next={loadMoreAsync} hasMore={hasMore} loader={this.loader()} dataLength={length} scrollableTarget={this.id}>
          <Table className="fs--1 mb-0 overflow-hidden" size="sm" striped role="table">{this.props.children}</Table>
        </Scroller>
      </Card.Body>
    );
  }

  loader() {
    return (
      <div className="text-center p-3">
        <Spinner animation="border" role="status" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }
}