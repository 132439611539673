import React from "react";
import SimpleBarReact from 'simplebar-react';
import { Card, Table } from "react-bootstrap";

interface Props {
}

export class PSTableBody extends React.PureComponent<Props> {
  render() {
    return (
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table className="fs--1 mb-0 overflow-hidden" size="sm" striped role="table">{this.props.children}</Table>
        </SimpleBarReact>
      </Card.Body>
    );
  }
}