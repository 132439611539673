import React from "react";
import { connect } from "react-redux";
import { IntlContext } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { IEmployee, IEmployeesProps } from "../Types";
import { EmployeeRow } from "../Components/EmployeeRow";
import { IRootState } from "../../../PSolutions.State";
import { PSHead } from "../../../PSolutions.UI/Table/Header/PSHead";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { PSTableLoader } from "../../../PSolutions.UI/Table/Loader";
import { PSTable } from "../../../PSolutions.UI/Table/Holder/PSTable";
import { PSHeader } from "../../../PSolutions.UI/Table/Header/PSHeader";
import { PSButton } from "../../../PSolutions.UI/Table/Items/PSButton";
import { PSFooter } from "../../../PSolutions.UI/Table/Footer/PSFooter";
import { PSHeadItem } from "../../../PSolutions.UI/Table/Items/PSHeadItem";
import { PSTableBody } from "../../../PSolutions.UI/Table/Holder/PSTableBody";
import { editEmployee, getEmployeesAsync, resetState } from "../Redux/Actions";

interface Props extends IEmployeesProps {
  resetState(): void;

  getEmployeesAsync(): any;

  navigate(to: string): void;

  editEmployee(employeeId: number): void;
}

class EmployeesContainer extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  constructor(props: Props) {
    super(props);
    this.handleNew = this.handleNew.bind(this);
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  async componentDidMount() {
    await this.props.getEmployeesAsync();
  }

  handleNew() {
    this.props.navigate("EmployeeUpsert")
  }

  render() {
    const {isBusy, editEmployee} = this.props;
    const employees = this.props.employees || [];

    return (
      <PSTable>
        <PSHeader title="Uposlenici">
          <PSButton title="Dodaj" icon="plus" onClick={this.handleNew}/>
        </PSHeader>
        <PSTableBody>
          <PSHead checkAll={false}>
            <PSHeadItem>Email adresa</PSHeadItem>
            <PSHeadItem>Ime</PSHeadItem>
            <PSHeadItem>Prezime</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Zaposlenik</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Moderator</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Admin</PSHeadItem>
            <PSHeadItem/>
          </PSHead>
          <tbody>
          {employees.map((m: IEmployee) => <EmployeeRow key={m.id} employee={m} edit={editEmployee}/>)}
          </tbody>
        </PSTableBody>
        <PSTableLoader show={isBusy}/>
        <PSFooter/>
      </PSTable>
    );
  }
}


const mapStateToProps = (state: IRootState) => {
  return {
    isEdit: state.employee.isEdit,
    isBusy: state.employee.isBusy,
    hasMore: state.employee.hasMore,
    pageSize: state.employee.pageSize,
    nextPage: state.employee.nextPage,
    showError: state.employee.showError,
    employees: state.employee.employees,
    errorMessage: state.employee.errorMessage,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getEmployeesAsync, editEmployee, resetState}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(EmployeesContainer));