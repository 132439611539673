import React from "react";

interface Props {
}

export class Content extends React.PureComponent<Props> {
  render() {
    return (
      <div className="content">{this.props.children}</div>
    );
  }
}