import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from 'formik';
import { bindActionCreators, Dispatch } from "redux";
import { EmployeeSchema } from "./EmployeeSchema";
import { IEmployee, IEmployeesProps } from "../Types";
import { IRootState } from "../../../PSolutions.State";
import { EmployeeUpsertView } from "../Views/EmployeeUpsertView";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { createEmployeeAsync, getEmployeeAsync, updateEmployeeAsync } from "../Redux/Actions";

interface Props extends IEmployeesProps {
  navigate(to: any): void;

  getEmployeeAsync(id: number): any;

  updateEmployeeAsync(app: IEmployee): any;

  createEmployeeAsync(app: IEmployee): any;
}

class EmployeeUpsertContainer extends React.PureComponent<Props> {
  private readonly Url = "/Employees";

  constructor(props: Props) {
    super(props);
    this.submitAsync = this.submitAsync.bind(this);
    this.createApplicationAsync = this.createApplicationAsync.bind(this);
    this.updateApplicationAsync = this.updateApplicationAsync.bind(this);
  }

  async componentDidMount() {
    if (this.props.selectedEmployee.id === 0) return;
    await this.props.getEmployeeAsync(this.props.selectedEmployee.id);
  }

  // Submit async
  async submitAsync(values: IEmployee, actions: FormikHelpers<any>) {
    if (this.props.isEdit) await this.updateApplicationAsync(values, actions);
    else await this.createApplicationAsync(values, actions);
  }

  // Create application
  async createApplicationAsync(employee: IEmployee, actions: FormikHelpers<any>) {
    const res = await this.props.createEmployeeAsync(employee);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(this.Url);
  }

  // Update application
  async updateApplicationAsync(employee: IEmployee, actions: FormikHelpers<any>) {
    const res = await this.props.updateEmployeeAsync(employee);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(this.Url);
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.submitAsync}
        component={EmployeeUpsertView}
        validationSchema={EmployeeSchema}
        initialValues={this.props.selectedEmployee}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isEdit: state.employee.isEdit,
    isBusy: state.employee.isBusy,
    showError: state.employee.showError,
    errorMessage: state.employee.errorMessage,
    selectedEmployee: state.employee.selectedEmployee,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getEmployeeAsync, updateEmployeeAsync, createEmployeeAsync}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(EmployeeUpsertContainer));
