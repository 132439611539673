import React from "react";

interface Props {
  center?: boolean;
  containerClassName?: string;
}

export class PSDataItem extends React.PureComponent<Props> {
  render() {
    const {center} = this.props;
    return (
      <td role="cell" className={this.props.containerClassName}>
        {!!center ? <div className="d-flex justify-content-center">{this.props.children}</div> : this.props.children}
      </td>
    )
  }
}