import React from "react";
import classNames from "classnames";
import { Container } from "react-bootstrap";

interface Props {
  bg?: string,
  fluid?: boolean,
  className: string;
}

export class AuthSection extends React.PureComponent<Props> {
  render() {
    const {fluid, bg, className} = this.props;
    return (
      <section className={classNames({[`bg-${bg}`]: bg}, className)}>
        <Container fluid={fluid}>{this.props.children}</Container>
      </section>
    );
  }
}