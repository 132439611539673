import axios from 'axios';
import { Dispatch } from "redux";
import { IEmployee } from "../Types";

export const GET_EMPLOYEES_SUCCESS = "Employees/GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "Employees/GET_EMPLOYEES_FAILURE";
export const GET_EMPLOYEES_REQUESTED = "Employees/GET_EMPLOYEES_REQUESTED";

export const GET_EMPLOYEE_SUCCESS = "Employees/GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAILURE = "Employees/GET_EMPLOYEE_FAILURE";
export const GET_EMPLOYEE_REQUESTED = "Employees/GET_EMPLOYEE_REQUESTED";

export const DELETE_EMPLOYEE_SUCCESS = "Employees/DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILURE = "Employees/DELETE_EMPLOYEE_FAILURE";
export const DELETE_EMPLOYEE_REQUESTED = "Employees/DELETE_EMPLOYEE_REQUESTED";

export const UPDATE_EMPLOYEES_SUCCESS = "Employees/UPDATE_EMPLOYEES_SUCCESS";
export const UPDATE_EMPLOYEES_FAILURE = "Employees/UPDATE_EMPLOYEES_FAILURE";
export const UPDATE_EMPLOYEES_REQUESTED = "Employees/UPDATE_EMPLOYEES_REQUESTED";

export const CREATE_EMPLOYEES_SUCCESS = "Employees/CREATE_EMPLOYEES_SUCCESS";
export const CREATE_EMPLOYEES_FAILURE = "Employees/CREATE_EMPLOYEES_FAILURE";
export const CREATE_EMPLOYEES_REQUESTED = "Employees/CREATE_EMPLOYEES_REQUESTED";

export const RESET_HARD = "Employees/RESET_HARD";
export const resetHard = () => (dispatch: Dispatch) => dispatch({type: RESET_HARD});

export const RESET_STATE = "Employees/RESET_STATE";
export const resetState = () => (dispatch: Dispatch) => dispatch({type: RESET_STATE});

export const EDIT_EMPLOYEE = "Employees/EDIT_EMPLOYEE"
export const editEmployee = (employeeId: number) => (dispatch: Dispatch) => dispatch({type: EDIT_EMPLOYEE, payload: employeeId});

export const getEmployeesAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_EMPLOYEES_REQUESTED});
      const response = await axios.get<Array<IEmployee>>("Employee");
      dispatch({type: GET_EMPLOYEES_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_EMPLOYEES_FAILURE});
      return null;
    }
  }
};

export const getEmployeeAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_EMPLOYEE_REQUESTED});
      const response = await axios.get<Array<IEmployee>>(`Employee/${id}`);
      dispatch({type: GET_EMPLOYEE_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_EMPLOYEE_FAILURE});
      return null;
    }
  }
};

export const deleteEmployee = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: DELETE_EMPLOYEE_REQUESTED});
      const response = await axios.delete(`Employee/${id}`);
      dispatch({type: DELETE_EMPLOYEE_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: DELETE_EMPLOYEE_FAILURE});
      return null;
    }
  }
};

export const createEmployeeAsync = (employee: IEmployee) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: CREATE_EMPLOYEES_REQUESTED});
      await axios.post("Employee", employee);
      dispatch({type: CREATE_EMPLOYEES_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: CREATE_EMPLOYEES_FAILURE});
      return false;
    }
  }
};

export const updateEmployeeAsync = (employee: IEmployee) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_EMPLOYEES_REQUESTED});
      await axios.put("Employee", employee);
      dispatch({type: UPDATE_EMPLOYEES_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: UPDATE_EMPLOYEES_FAILURE});
      return false;
    }
  }
};

