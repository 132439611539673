import * as actions from './Actions'
import { IEmployeesProps } from "../Types";

const initialState: IEmployeesProps = {
  page: 1,
  pageSize: 50,
  nextPage: 1,
  isBusy: false,
  isEdit: false,
  totalCount: 0,
  hasMore: false,
  errorMessage: "",
  showError: false,
  isDeleting: false,
  employees: [],
  selectedEmployee: {
    id: 0,
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    confirmPassword: "",
    hasAdminRole: false,
    hasEmployeeRole: true,
    hasModeratorRole: false,
  }
}

const EmployeesReducer = (state: IEmployeesProps = initialState, action: any) => {
  switch (action.type) {
    // GET
    case actions.GET_EMPLOYEES_REQUESTED:
      return {...state, isBusy: true};
    case actions.GET_EMPLOYEES_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.GET_EMPLOYEES_SUCCESS:
      return {...state, isBusy: false, ...action.payload, employees: [...state.employees, ...action.payload]};

    // GET
    case actions.GET_EMPLOYEE_REQUESTED:
      return {...state, isBusy: true};
    case actions.GET_EMPLOYEE_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.GET_EMPLOYEE_SUCCESS:
      return {...state, isBusy: false, selectedEmployee: action.payload};

    // ADD
    case actions.CREATE_EMPLOYEES_REQUESTED:
      return {...state, isBusy: true};
    case actions.CREATE_EMPLOYEES_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.CREATE_EMPLOYEES_SUCCESS:
      return {...state, ...initialState};

    // UPDATE
    case actions.UPDATE_EMPLOYEES_REQUESTED:
      return {...state, isBusy: true};
    case actions.UPDATE_EMPLOYEES_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.UPDATE_EMPLOYEES_SUCCESS:
      return {...state, ...initialState};

    // UPDATE
    case actions.DELETE_EMPLOYEE_REQUESTED:
      return {...state, isDeleting: true};
    case actions.DELETE_EMPLOYEE_FAILURE:
      return {...state, isDeleting: false, showError: true, errorMessage: action.payload};
    case actions.DELETE_EMPLOYEE_SUCCESS:
      return {...state, ...initialState};

    // Utilities
    case actions.EDIT_EMPLOYEE:
      return {...state, isEdit: true, selectedEmployee: {...state.selectedEmployee, id: action.payload}}
    case actions.RESET_STATE:
      return {...state, ...initialState, isEdit: state.isEdit, selectedEmployee: {...state.selectedEmployee}};
    case actions.RESET_HARD:
      return {...state, ...initialState};
    default:
      return state;
  }
}

export { EmployeesReducer };