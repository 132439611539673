import React from "react";
import { PSRowCheck } from "../Items/PSRowCheck";
import { PSHeadItem } from "../Items/PSHeadItem";

interface Props {
  checkAll: boolean;
}

export class PSHead extends React.PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        <thead className="bg-200 text-900 text-nowrap align-middle">
        <tr>
          <PSHeadItem appliedCss="table-checkbox-w">
            <PSRowCheck checked={this.props.checkAll}/>
          </PSHeadItem>
          {this.props.children}
        </tr>
        </thead>
      </React.Fragment>
    );
  }
}