import React from "react";
import classNames from "classnames";


interface Props {
  appliedCss?: string;
}

export class PSHeadItem extends React.PureComponent<Props> {
  render() {
    const css = classNames("cursor", this.props.appliedCss);
    return <th colSpan={1} role="columnheader" className={css}>{this.props.children}</th>;
  }
}