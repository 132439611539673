import React from "react";
import { Nav, Navbar } from "react-bootstrap";

interface Props {
  children: any;
}

export class NavContent extends React.PureComponent<Props> {
  render() {
    return (
      <Navbar.Collapse id="responsive-navbar-nav">
        <div className="navbar-vertical-content scrollbar vh-100">
          <Nav className="flex-column" as="ul">{this.props.children}</Nav>
        </div>
      </Navbar.Collapse>
    );
  }
}