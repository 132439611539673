import React from "react";

interface Props {
  checked: boolean;
}

export class PSRowCheck extends React.PureComponent<Props> {
  handleChange() {
  }

  render() {
    return (
      <div className="form-check fs-0 mb-0 d-flex align-items-center">
        <input title="Toggle" type="checkbox" className="form-check-input cursor" checked={this.props.checked} onChange={this.handleChange}/>
      </div>
    );
  }
}