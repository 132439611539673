import React from "react";
import { Container } from "react-bootstrap";
import { Route, Routes, Navigate } from "react-router-dom";
import { RolesEnum } from "../PSolutions.Enum/Roles";
import { RouteGuard } from "../PSolutions.Guard/RouteGuard";
import { TopNavbar } from "../PSolutions.Layout/Navbar/TopNavbar";
import { Content } from "../PSolutions.Layout/MainContent/Content";
import ProfileDropdown from "../PSolutions.UI/Navbar/ProfileDropdown";
import { SidebarNavigation } from "../PSolutions.Layout/Navigation/SidebarNavigation";
import OrdersContainer from "../PSolutions.Container/Orders/Container/OrdersContainer";
import EmployeesContainer from "../PSolutions.Container/Employees/Container/EmployeesContainer";
import OrdersUpsertContainer from "../PSolutions.Container/Orders/Container/OrdersUpsertContainer";
import ProductionsContainer from "../PSolutions.Container/Production/Container/ProductionsContainer";
import EmployeeUpsertContainer from "../PSolutions.Container/Employees/Container/EmployeeUpsertContainer";
import ProductionUpsertContainer from "../PSolutions.Container/Production/Container/ProductionUpsertContainer";

interface Props {
  show: boolean;
}

interface State {
  expanded: boolean;
}

export class AdminDashboard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {expanded: false}
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  closeMenu() {
    this.setState({expanded: false});
  }

  toggleMobileMenu() {
    this.setState({expanded: !this.state.expanded})
  }

  render() {
    const {expanded} = this.state;
    if (!this.props.show) return null;
    return (
      <Container fluid={true}>
        <SidebarNavigation expanded={expanded} onItemClick={this.closeMenu}/>
        <Content>
          <TopNavbar toggle={this.toggleMobileMenu}>
            <ProfileDropdown/>
          </TopNavbar>
          <Routes>
            <Route path="/" element={<Navigate to="Orders"/>}/>
            <Route path="Production" element={<ProductionsContainer/>}/>
            <Route path="Orders/OrderUpsert" element={<OrdersUpsertContainer/>}/>
            <Route path="LegalOrders/OrderUpsert" element={<OrdersUpsertContainer/>}/>
            <Route path="Orders" element={<OrdersContainer key={2} entityType={2}/>}/>
            <Route path="LegalOrders" element={<OrdersContainer key={1} entityType={1}/>}/>
            <Route path="Employees/EmployeeUpsert" element={<EmployeeUpsertContainer/>}/>
            <Route path="Production/ProductionUpsert" element={<ProductionUpsertContainer/>}/>
            <Route path="Employees" element={<RouteGuard role={RolesEnum.Admin}><EmployeesContainer/></RouteGuard>}/>
          </Routes>
        </Content>
      </Container>
    );
  }
}