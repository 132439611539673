import React from "react";
import { FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Spinner } from "react-bootstrap";
import { IEmployee } from "../Types";
import { deleteEmployee } from "../Redux/Actions";
import { IRootState } from "../../../PSolutions.State";
import { PSFormRow } from "../../../PSolutions.UI/Form/PSFormRow";
import { PSFormCard } from "../../../PSolutions.UI/Form/PSFormCard";
import { InputField } from "../../../PSolutions.UI/Fields/InputField";
import { PSFormHolder } from "../../../PSolutions.UI/Form/PSFormHolder";
import { PSFormFooter } from "../../../PSolutions.UI/Form/PSFormFooter";
import { CheckField } from "../../../PSolutions.UI/Fields/CheckField";

interface Props extends FormikProps<IEmployee> {
}

export const EmployeeUpsertView = React.memo(({submitForm, isSubmitting, isValid}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBackClick = () => navigate(-1)
  const {isEdit, selectedEmployee, isDeleting} = useSelector((state: IRootState) => state.employee);

  async function deleteAsync() {
    await dispatch(deleteEmployee(selectedEmployee.id))
    handleBackClick();
  }

  const passwordCss = !!isEdit ? "d-none" : "";

  return (
    <PSFormHolder>
      <PSFormCard title="Novi uposlenik" columns={8}>
        <PSFormRow>
          <InputField
            colNum={12}
            type="email"
            id="email"
            name="email"
            required={true}
            label="Email adresa"
            placeholder="Unesite email adresu"
          />
        </PSFormRow>
        <PSFormRow>
          <InputField
            type="text"
            colNum={6}
            label="Ime"
            id="firstName"
            required={true}
            name="firstName"
            placeholder="Unesite ime"
          />
          <InputField
            colNum={6}
            type="text"
            required={true}
            id="lastName"
            name="lastName"
            label="Prezime"
            placeholder="Unesite prezime"
          />
        </PSFormRow>
        <PSFormRow>
          <InputField
            id="password"
            name="password"
            type="password"
            colNum={6}
            label="Lozinka"
            required={true}
            className={passwordCss}
            placeholder="Unesite lozinku"
          />
          <InputField
            colNum={6}
            required={true}
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className={passwordCss}
            label="Ponovljena lozinka"
            placeholder="Ponovi lozinku"
          />
        </PSFormRow>
        <PSFormFooter>
          <Button variant="falcon-outline" className="me-2" onClick={handleBackClick}>Nazad</Button>
          <Button variant="primary" className="w-auto" type="button" disabled={isSubmitting || !isValid} onClick={submitForm}>
            {isSubmitting ? <Spinner animation="border" size="sm"/> : "Spremi"}
          </Button>
        </PSFormFooter>
      </PSFormCard>
      <Col lg={4}>
        <div className="sticky-sidebar">
          <PSFormCard title="Prava pristupa" className="mb-3">
            <div className="ps-2">
              <h6 className="fw-bold">Nivo pristupa</h6>
              <CheckField id="admin-check" name="hasAdminRole" className="form-label-nogutter" label="Admin"/>
              <CheckField id="moderator-check" name="hasModeratorRole" className="form-label-nogutter" label="Moderator"/>
              <CheckField id="employee-check" name="hasEmployeeRole" className="form-label-nogutter" label="Zaposlenik"/>
            </div>
          </PSFormCard>
          <PSFormCard title="Opasna zona" className="mb-3">
            <h5 className="mb-0">Obriši ovog korisnika</h5>
            <p className="fs--1">Nakon što izbrišete korisnika, nema povratka. Budite sigurni.</p>
            <Button variant="falcon-danger" disabled={!isEdit} className="w-100" onClick={deleteAsync}> {isDeleting ? <Spinner animation="border" size="sm"/> : "Obriši"}</Button>
          </PSFormCard>
        </div>
      </Col>
    </PSFormHolder>
  );
});