import React from "react";
import { Card } from "react-bootstrap";

interface Props {
}

export class PSTable extends React.PureComponent<Props> {
  render() {
    return (
      <Card className="mb-3">{this.props.children}</Card>
    );
  }
}