import React from "react";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  isFluid: boolean;
}


export class ToggleButton extends React.PureComponent<Props> {

  getTooltip(props: any) {
    return <Tooltip {...props} id="button-tooltip">Toggle navigation</Tooltip>
  }

  render() {
    return (
      <OverlayTrigger overlay={this.getTooltip} placement="right" defaultShow={false}>
        <div className="toggle-icon-wrapper">
          <Button variant="link" className="navbar-toggler-humburger-icon navbar-vertical-toggle">
            <span className="navbar-toggle-icon"><span className="toggle-line"/></span>
          </Button>
        </div>
      </OverlayTrigger>
    );
  }
}