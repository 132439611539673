import React from "react";
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  at: string;
  width: number,
  textClass?: string;
}

export class Logo extends React.PureComponent<Props> {
  render() {
    return (
      <Link to="/" className="text-decoration-none navbar-brand text-left">
        <div className="d-flex align-items-center py-3">
          <span className={classNames('font-sans-serif', this.props.textClass)}>Nansi</span>
        </div>
      </Link>
    );
  }
}