import React from "react";
import { Link } from "react-router-dom";
import { IEmployee } from "../Types";
import { PSCheckItem } from "../../../PSolutions.UI/Table/Items/PSCheckItem";
import { PSDataItem } from "../../../PSolutions.UI/Table/Items/PSDataItem";
import { PSRowCheck } from "../../../PSolutions.UI/Table/Items/PSRowCheck";

interface Props {
  employee: IEmployee,

  edit(employeeId: number): void;
}

export class EmployeeRow extends React.PureComponent<Props> {

  render() {
    const {employee} = this.props;
    return (
      <tr className="align-middle white-space-nowrap">
        <PSCheckItem checked={false}/>
        <PSDataItem>
          <Link to="EmployeeUpsert" onClick={() => this.props.edit(employee.id)}>
            <h5 className="mb-0 fs--1">{employee.email}</h5>
          </Link>
        </PSDataItem>
        <PSDataItem>{employee.firstName}</PSDataItem>
        <PSDataItem>{employee.lastName}</PSDataItem>
        <PSDataItem center>
          <PSRowCheck checked={employee.hasEmployeeRole}/>
        </PSDataItem>
        <PSDataItem center>
          <PSRowCheck checked={employee.hasModeratorRole}/>
        </PSDataItem>
        <PSDataItem center>
          <PSRowCheck checked={employee.hasAdminRole}/>
        </PSDataItem>
        <PSDataItem containerClassName="text-end"/>
      </tr>
    );
  }
}