import React from "react";
import { ToggleButton } from "../../Components/ToggleButton";
import { Logo } from "../../../PSolutions.UI/Shared/Logo";

interface Props {

}

export class NavHeader extends React.PureComponent<Props> {
  render() {
    return (
      <div className="d-flex align-items-center">
        <ToggleButton isFluid={false}/>
        <Logo at="navbar-vertical" width={40}/>
      </div>
    );
  }
}