import React from "react";
import { IntlContext } from "react-intl";
import { Card, Col, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthSection } from "../PSolutions.Layout/Authentication/AuthSection";
import SignInContainer from "../PSolutions.Container/Signin/Container/SigninContainer";

interface Props {
  show: boolean;
}

export class GuestDashboard extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    if (!this.props.show) return null;
    return (
      <AuthSection className="py-0">
        <Row className="justify-content-center min-vh-100 py-10">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <Routes>
                  <Route path="/" element={<SignInContainer/>}/>
                  <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AuthSection>
    );
  }
}