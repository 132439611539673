import React from "react";
import { IconButton } from "../../Buttons/IconButton";

interface Props {
  transform?: any
  btnClass?: string;
  title: string;
  icon: string;

  onClick?(): void;
}

export class PSButton extends React.PureComponent<Props> {
  render() {
    const {icon, title, btnClass, transform, onClick} = this.props;
    return (
      <IconButton variant="falcon-default" size="sm" icon={icon} transform={transform} className={btnClass} onClick={onClick}>
        <span className="d-none d-sm-inline-block ms-1 d-inline-block">{title}</span>
      </IconButton>
    );
  }
}