import React from "react";
import { PSRowCheck } from "./PSRowCheck";
import { PSDataItem } from "./PSDataItem";

interface Props {
  checked: boolean;
  appliedCss?: string;
}

export class PSCheckItem extends React.PureComponent<Props> {
  render() {
    return (
      <PSDataItem containerClassName={this.props.appliedCss}>
        <div className="d-flex justify-content-center">
          <PSRowCheck checked={this.props.checked}/>
        </div>
      </PSDataItem>
    )
  }
}