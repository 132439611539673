import { PSolutionsValidation } from "../../../PSolutions.Providers/Validation";

export const OrderSchema = PSolutionsValidation.object().shape({
  city: PSolutionsValidation.string().required().min(2),
  address: PSolutionsValidation.string().required(),
  buyerName: PSolutionsValidation.string().required(),
  phoneNumber: PSolutionsValidation.string().required(),
  price: PSolutionsValidation.number().required().positive(),
  quantity: PSolutionsValidation.number().required().positive(),
  deliveredQuantity: PSolutionsValidation.number().required().min(0).max(PSolutionsValidation.ref("quantity")),
});
