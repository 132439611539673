import React from 'react';
import axios from "axios";
import axiosRetry from "axios-retry";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { bindActionCreators, Dispatch } from "redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import { IRootState } from "../../PSolutions.State";
import { persistor } from "../../PSolutions.Store/AdminStore";
import enTranslation from "../../PSolutions.Resources/en.json";
import bsTranslation from "../../PSolutions.Resources/bs.json";
import { API_URL } from "../../PSolutions.Config/ApplicationSettings";
import { AdminDashboard } from "../../PSolutions.Pages/AdminDashboard";
import { GuestDashboard } from "../../PSolutions.Pages/GuestDashboard";
import validationMessages from "../../PSolutions.Resources/validation-en.json";
import { registerRequestInterceptor } from "../../PSolutions.Http/RequestInterceptors";
import { TranslationProvider } from "../../PSolutions.Providers/Translation/TranslationProvider";

interface Props {
  currentLanguage: string;
  isAuthenticated: boolean;
}

const translations: any = {
  en: {
    ...enTranslation,
    ...validationMessages
  },
  bs: {
    ...bsTranslation,
    ...validationMessages
  }
}

/**
 *  Configure axios retry with exponential backoff.
 *  Configures axios interceptor for refresh token.
 *  Only network request will repeated.
 */

axios.defaults.baseURL = API_URL;
axiosRetry(axios, {retries: 5, retryDelay: axiosRetry.exponentialDelay, retryCondition: axiosRetry.isNetworkError})

class AdminApplication extends React.PureComponent<Props> {
  handleBeforeLift = () => registerRequestInterceptor();

  render() {
    const {isAuthenticated, currentLanguage} = this.props;
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <PersistGate persistor={persistor} onBeforeLift={this.handleBeforeLift}>
          <TranslationProvider currentLanguage="bs">
            <IntlProvider locale={currentLanguage} defaultLocale="bs" messages={translations[currentLanguage]}>
              <AdminDashboard show={isAuthenticated}/>
              <GuestDashboard show={!isAuthenticated}/>
            </IntlProvider>
          </TranslationProvider>
        </PersistGate>
      </Router>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    currentLanguage: state.app.currentLanguage,
    isAuthenticated: state.app.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminApplication);
