import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends ButtonProps {
  icon: any,
  transform?: any;
}

export class IconButton extends React.PureComponent<Props> {
  render() {
    const {icon, transform, ...rest} = this.props;
    return (
      <Button {...rest}>
        <FontAwesomeIcon icon={icon} className="me-1"/>
        {this.props.children}
      </Button>
    );
  }
}