import React from "react";
import DatePicker from 'react-datepicker';
import BsLocale from 'date-fns/locale/bs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { CustomDateInput } from "./CustomDateInput";
import { PSButton } from "../../../PSolutions.UI/Table/Items/PSButton";

interface Props {
  city: string;
  priority?: number;
  buyerName: string;
  quantity?: number;
  startDate?: Date;
  endDate?: Date,
  deliveryStatus?: number;

  handleNew(): void;

  handleCityChange(city: string): void;

  handleDatesChange(dates: any): void;

  handleQuantityChange(quantity?: number): void;

  handleBuyerChange(buyer: string): void;

  handleStatusChange(status?: number): void;

  handlePriorityChange(deliverPriority?: number): void;
}

export class OrdersHeader extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onCityChange = this.onCityChange.bind(this);
    this.onBuyerChange = this.onBuyerChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleDeliverPriorityChange = this.handleDeliverPriorityChange.bind(this);
  }

  onBuyerChange(e: any) {
    this.props.handleBuyerChange(e.target.value)
  }

  onCityChange(e: any) {
    this.props.handleCityChange(e.target.value)
  }

  handleStatusChange(e: any) {
    this.props.handleStatusChange(e.target.value);
  }

  handleDeliverPriorityChange(e: any) {
    this.props.handlePriorityChange(e.target.value);
  }

  handleQuantityChange(e: any) {
    this.props.handleQuantityChange(e.target.value);
  }

  handleDatesChange(dates: any) {
    this.props.handleDatesChange(dates);
  }

  render() {
    return (
      <Card.Header>
        <Row className="flex-between-center">
          <Col sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Narudžbe</h5>
          </Col>
          <Col sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions" className="d-flex justify-content-center">
              <InputGroup size="sm" className="me-2 ms-3">
                <FormControl
                  size="sm"
                  type="search"
                  id="buyer-search"
                  className="shadow-none"
                  value={this.props.buyerName}
                  placeholder="Pretraži kupce"
                  onChange={this.onBuyerChange}
                />
                <InputGroup.Text className="secondary">
                  <FontAwesomeIcon icon="user" className="fs--1 text-600"/>
                </InputGroup.Text>
              </InputGroup>
              <InputGroup size="sm" className="me-2">
                <FormControl
                  size="sm"
                  type="search"
                  id="city-search"
                  className="shadow-none"
                  value={this.props.city}
                  placeholder="Pretraži grad"
                  onChange={this.onCityChange}
                />
                <InputGroup.Text className="secondary">
                  <FontAwesomeIcon icon="map-location-dot" className="fs--1 text-600"/>
                </InputGroup.Text>
              </InputGroup>
              <InputGroup size="sm" className="me-2">
                <Form.Select className="pe-5 cursor-pointer" value={this.props.priority} onChange={this.handleDeliverPriorityChange}>
                  <option className="cursor-pointer" value="">Svi prioriteti</option>
                  <option className="cursor-pointer" value={1}>Visok prioritet</option>
                  <option className="cursor-pointer" value={0}>Normalan prioritet</option>
                </Form.Select>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="sort" className="fs--1"/>
                </InputGroup.Text>
              </InputGroup>
              <InputGroup size="sm" className="me-2">
                <Form.Select className="pe-5 cursor-pointer" value={this.props.deliveryStatus} onChange={this.handleStatusChange}>
                  <option className="cursor-pointer" value="">Svi statusi</option>
                  <option className="cursor-pointer" value={0}>Naručeno</option>
                  <option className="cursor-pointer" value={1}>U procesu</option>
                  <option className="cursor-pointer" value={2}>Isporučeno</option>
                </Form.Select>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="sort" className="fs--1"/>
                </InputGroup.Text>
              </InputGroup>
              <InputGroup size="sm" className="me-2">
                <DatePicker
                  selectsRange
                  locale={BsLocale}
                  dateFormat="dd MMM"
                  endDate={this.props.endDate}
                  selected={this.props.startDate}
                  startDate={this.props.startDate}
                  customInput={<CustomDateInput/>}
                  formatWeekDay={day => day.slice(0, 3)}
                  onChange={this.handleDatesChange}
                />
              </InputGroup>
              <InputGroup size="sm" className="me-2">
                <FormControl
                  size="sm"
                  type="decimal"
                  id="quantity-search"
                  className="shadow-none"
                  value={this.props.quantity}
                  placeholder="Unesi količinu"
                  onChange={this.handleQuantityChange}
                />
                <InputGroup.Text className="secondary">
                  <FontAwesomeIcon icon="map-location-dot" className="fs--1 text-600"/>
                </InputGroup.Text>
              </InputGroup>
              <PSButton title="Nova" icon="plus" onClick={this.props.handleNew} btnClass="width-100px"/>
            </div>
          </Col>
        </Row>
      </Card.Header>
    );
  }
}