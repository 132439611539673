import React from "react";
import Avatar from 'react-avatar';
import { connect } from "react-redux";
import { IntlContext } from "react-intl";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../PSolutions.State";
import { logout } from "../../PSolutions.App/Redux/Actions";

interface Props {
  fullName: string;

  logout(): void;
}

class ProfileDropdown extends React.PureComponent<Props> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render() {
    const logout = this.context.formatMessage({id: "generic.messages.logout"})
    return (
      <Dropdown navbar as="li">
        <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="pe-0 ps-2 nav-link">
          <span className="avatar-margin">{this.props.fullName}</span>
          <Avatar size="35" style={{marginTop: "-3px"}} round name={this.props.fullName} className=""/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item as={Link} to="/" onClick={this.props.logout}>{logout}</Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    fullName: state.app.fullName,
    isAuthenticated: state.app.isAuthenticated,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({logout}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);