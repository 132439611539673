import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { bindActionCreators, Dispatch } from "redux";
import { LoginSchema } from "./LoginSchema";
import { SignInView } from "../Views/SignInView";
import { IRootState } from "../../../PSolutions.State";
import { ICredentials } from "../../../PSolutions.App/Types";
import { login } from "../../../PSolutions.App/Redux/Actions";

interface Props {
  login(credentials: ICredentials): any;
}

interface State {
  email: string;
  password: string;
}

class SignInContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {email: "", password: ""};
    this.handleSubmitAsync = this.handleSubmitAsync.bind(this);
  }

  async loginAsync(data: State) {
    const response = await axios.post<ICredentials>('authentication', data);
    if (!!response.data) this.props.login(response.data);
  }

  async handleSubmitAsync(values: State, actions: FormikHelpers<State>) {
    await this.loginAsync(values);
    actions.setSubmitting(false);
  }

  render() {
    return (
      <Formik
        component={SignInView}
        initialValues={this.state}
        validationSchema={LoginSchema}
        onSubmit={this.handleSubmitAsync}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({login}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);