import React from "react";
import { FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Spinner} from "react-bootstrap";
import { IOrder } from "../Types";
import { deleteOrderAsync} from "../Redux/Actions";
import { IRootState } from "../../../PSolutions.State";
import { PSFormRow } from "../../../PSolutions.UI/Form/PSFormRow";
import { PSFormCard } from "../../../PSolutions.UI/Form/PSFormCard";
import { InputField } from "../../../PSolutions.UI/Fields/InputField";
import { PSFormHolder } from "../../../PSolutions.UI/Form/PSFormHolder";
import { PSFormFooter } from "../../../PSolutions.UI/Form/PSFormFooter";
import { TextAreaField } from "../../../PSolutions.UI/Fields/TextAreaField";
import { InputGroupField } from "../../../PSolutions.UI/Fields/InputGroupField";
import { RadioField } from "../../../PSolutions.UI/Fields/RadioField";

interface Props extends FormikProps<IOrder> {
}

export const OrderUpsertView = React.memo(({submitForm, isSubmitting, isValid}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBackClick = () => navigate(-1)
  const {isEdit, selectedItem, isDeleting, isBusy} = useSelector((state: IRootState) => state.order);

  async function deleteAsync() {
    await dispatch(deleteOrderAsync(selectedItem.id))
    handleBackClick();
  }

  return (
    <PSFormHolder>
      <PSFormCard title="Nova narudžba" columns={8}>
        <PSFormRow>
          <TextAreaField
            colNum={6}
            rows={3}
            id="buyerName"
            name="buyerName"
            required={true}
            label="Kupac"
            disabled={isBusy}
            placeholder="Unesite kupca"
          />
          <TextAreaField
            rows={3}
            colNum={6}
            id="phoneNumber"
            required={true}
            name="phoneNumber"
            disabled={isBusy}
            label="Broj telefona"
            placeholder="Unesite broj telefona"
          />
        </PSFormRow>
        <PSFormRow>
          <TextAreaField
            rows={2}
            colNum={6}
            label="Grad"
            id="city"
            name="city"
            required={true}
            disabled={isBusy}
            placeholder="Unesite grad"
          />
          <TextAreaField
            rows={2}
            colNum={6}
            required={true}
            id="address"
            name="address"
            label="Adresa"
            disabled={isBusy}
            placeholder="Unesite adresu"
          />
        </PSFormRow>
        <PSFormRow>
          <InputField
            type="date"
            colNum={4}
            required={true}
            disabled={isBusy}
            label="Rok isporuke"
            id="pelletDeliveryDate"
            name="pelletDeliveryDate"
            placeholder="Rok isporuke"
          />
          <InputGroupField
            type="text"
            colNum={4}
            id="price"
            required={true}
            name="price"
            label="Cijena"
            suffix="KM/T"
            disabled={isBusy}
            placeholder="Unesite cijenu"
          />
          <InputGroupField
            colNum={4}
            type="number"
            id="quantity"
            suffix="Tona"
            name="quantity"
            required={true}
            label="Količina"
            disabled={isBusy}
            placeholder="Unesite količinu"
          />
        </PSFormRow>
        <PSFormFooter>
          <Button variant="falcon-outline" className="me-2" onClick={handleBackClick}>Nazad</Button>
          <Button variant="primary" className="w-auto" type="button" disabled={isSubmitting || !isValid} onClick={submitForm}>
            {isSubmitting ? <Spinner animation="border" size="sm"/> : "Spremi"}
          </Button>
        </PSFormFooter>
      </PSFormCard>
      <Col columns={4}>
        <div className="sticky-sidebar">
          <PSFormCard title="Isporučena količina" className="mb-3">
            <h6 className="fw-bold">Prioritet narudžbe ?</h6>
            <RadioField id="normal-priority" defaultValue={0} name="deliveryPriority" label="Normalan" disabled={!isEdit}/>
            <RadioField id="max-priority" defaultValue={1} name="deliveryPriority" label="Visok" disabled={!isEdit}/>
            <h6 className="fw-bold">Status narudžbe ?</h6>
            <RadioField id="ordered-status" defaultValue={0} name="deliveryStatus" label="Naručena" disabled={!isEdit}/>
            <RadioField id="process-status" defaultValue={1} name="deliveryStatus" label="U procesu" disabled={!isEdit}/>
            <RadioField id="delivered-status" defaultValue={2} name="deliveryStatus" label="Isporučeno" disabled={!isEdit}/>
            <InputGroupField
              type="number"
              id="quantity"
              suffix="Tona"
              required={true}
              className="mb-3"
              disabled={!isEdit}
              name="deliveredQuantity"
              label="Isporučena količina"
              placeholder="Unesite isporučenu količinu"
            />
            <Button variant="falcon-primary" className="w-100" onClick={submitForm} disabled={isSubmitting || !isValid || !isEdit}>
              {isSubmitting ? <Spinner animation="border" size="sm"/> : "Spremi"}
            </Button>
          </PSFormCard>
          <PSFormCard title="Opasna zona" className="mb-3">
            <h5 className="mb-0">Obriši ovog narudžbu</h5>
            <p className="fs--1">Nakon što izbrišete narudžbu, nema povratka. Da li ste sigurni?</p>
            <Button variant="falcon-danger" disabled={!isEdit} className="w-100" onClick={deleteAsync}> {isDeleting ? <Spinner animation="border" size="sm"/> : "Obriši"}</Button>
          </PSFormCard>
        </div>
      </Col>
    </PSFormHolder>
  );
});