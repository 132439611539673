import React from "react";
import _ from "lodash";
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../../PSolutions.State";
import { OrdersCount } from "../Components/OrdersCount";
import { TotalDemand } from "../Components/TotalDemand";
import { OrdersHeader } from "../Components/OrdersHeader";
import { TotalDelivery } from "../Components/TotalDelivery";
import { PSTableLoader } from "../../../PSolutions.UI/Table/Loader";
import { PSHead } from "../../../PSolutions.UI/Table/Header/PSHead";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { SoftBadge } from "../../../PSolutions.UI/Badges/SoftBadge";
import { IFilters, IOrder, IOrderProps, IOrderQuery } from "../Types";
import { PSTable } from "../../../PSolutions.UI/Table/Holder/PSTable";
import { PSFooter } from "../../../PSolutions.UI/Table/Footer/PSFooter";
import { PSHeadItem } from "../../../PSolutions.UI/Table/Items/PSHeadItem";
import { PSDataItem } from "../../../PSolutions.UI/Table/Items/PSDataItem";
import { PSCheckItem } from "../../../PSolutions.UI/Table/Items/PSCheckItem";
import { PSTableScroll } from "../../../PSolutions.UI/Table/Holder/PSTableScroll";
import { TranslationContext } from "../../../PSolutions.Providers/Translation/TranslationContext";
import { editItem, getOrdersAsync, getOrderAsync, resetState, clearData, setEntityType, setFilters } from "../Redux/Actions";
import { RemainingDelivery } from "../Components/RemainingDelivery";
import BsLocale from "date-fns/locale/bs";

interface Props extends IOrderProps {
  entityType: number;

  clearData(): void;

  resetState(): void;

  editItem(id: number): void;

  navigate(to: string): void;

  setFilters(filters: IFilters): void;

  setEntityType(entityType: number): void;

  getOrdersAsync(query: IOrderQuery): any;
}

interface State {
  isLoading: boolean;
}

class OrdersContainer extends React.PureComponent<Props, State> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;
  private readonly deferredSearchAsync: (page: number, shouldReset?: boolean) => any;

  constructor(props: Props) {
    super(props);
    this.state = {isLoading: true,};
    this.handleNew = this.handleNew.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.loadMoreAsync = this.loadMoreAsync.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.fetchOrdersAsync = this.fetchOrdersAsync.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleCityNameChange = this.handleCityNameChange.bind(this);
    this.fetchMoreOrdersAsync = this.fetchMoreOrdersAsync.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleBuyerNameChange = this.handleBuyerNameChange.bind(this);
    this.deferredSearchAsync = _.debounce(this.fetchOrdersAsync, 50) as any;
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  async componentDidMount() {
    await this.fetchOrdersAsync(1, true);
  }

  async fetchOrdersAsync(page: number, shouldReset?: boolean,) {
    let endDateString = "";
    let startDateString = "";
    this.setState({isLoading: true})
    if (!!shouldReset) this.props.clearData();

    const {pageSize, entityType} = this.props;
    const {city, buyerName, deliveryStatus, priority, quantity, startDate, endDate} = this.props.filters;

    if (!!startDate) startDateString = dateFns.format(this.props.filters.startDate as any, "yyyy-MM-dd", {locale: BsLocale});
    if (!!endDate) endDateString = dateFns.format(this.props.filters.endDate as any, "yyyy-MM-dd", {locale: BsLocale});

    const query: IOrderQuery = {page, pageSize, buyerName, deliveryStatus, entityType, city, priority, startDate: startDateString, endDate: endDateString, quantity};

    await this.props.getOrdersAsync(query);
    this.setState({isLoading: false})
  }

  async fetchMoreOrdersAsync(page: number) {
    let endDateString = "";
    let startDateString = "";

    const {pageSize, entityType} = this.props;
    const {city, buyerName, deliveryStatus, priority, quantity, startDate, endDate} = this.props.filters;

    if (!!startDate) startDateString = dateFns.format(this.props.filters.endDate as any, "yyyy-MM-dd", {locale: BsLocale});
    if (!!endDate) endDateString = dateFns.format(this.props.filters.endDate as any, "yyyy-MM-dd", {locale: BsLocale});

    const query: IOrderQuery = {page, pageSize, buyerName, deliveryStatus, entityType, city, priority, startDate: startDateString, endDate: endDateString, quantity};
    await this.props.getOrdersAsync(query);
  }

  async loadMoreAsync() {
    await this.fetchMoreOrdersAsync(this.props.nextPage);
  }

  async handleStatusChange(status: number) {
    let filters = this.props.filters;
    filters.deliveryStatus = status;

    this.props.setFilters(filters)
    await this.fetchOrdersAsync(1, true)
  }

  async handleBuyerNameChange(buyer: string) {
    let filters = this.props.filters;
    filters.buyerName = buyer;

    this.props.setFilters(filters)
    await this.deferredSearchAsync(1, true)
  }

  async handleCityNameChange(city: string) {
    let filters = this.props.filters;
    filters.city = city;

    this.props.setFilters(filters)
    await this.deferredSearchAsync(1, true)
  }

  async handleQuantityChange(quantity?: number) {
    let filters = this.props.filters;
    filters.quantity = quantity;

    this.props.setFilters(filters)
    await this.deferredSearchAsync(1, true)
  }

  async handleDatesChange(dates: any) {
    const [start, end] = dates;
    let filters = this.props.filters;

    filters.endDate = end;
    filters.startDate = start;
    this.props.setFilters(filters)

    await this.deferredSearchAsync(1, true)
  }

  getBackground(status: number) {
    if (status === 0) return "primary";
    if (status === 1) return "warning";
    if (status === 2) return "success";
  }

  getStatus(status?: number) {
    if (status === 0) return "Naručeno";
    if (status === 1) return "U procesu";
    if (status === 2) return "Isporučeno";
  }

  handleNew() {
    this.props.setEntityType(this.props.entityType);
    this.props.navigate("OrderUpsert")
  }

  handleEdit(id: number) {
    this.props.setEntityType(this.props.entityType);
    this.props.editItem(id);
  }

  async handlePriorityChange(priority?: number) {
    let filters = this.props.filters;
    filters.priority = priority;

    this.props.setFilters(filters)
    await this.fetchOrdersAsync(1, true)
  }

  formatDeliveredQuantity(quantity: number) {
    if (!quantity) return "-";
    return quantity === 0 ? "-" : `${quantity} t`;
  }

  render() {
    let {hasMore} = this.props;
    const items = this.props.items || [];
    hasMore = this.state.isLoading ? false : hasMore;

    return (
      <PSTable>
        <OrdersHeader
          handleNew={this.handleNew}
          city={this.props.filters.city}
          priority={this.props.filters.priority}
          buyerName={this.props.filters.buyerName}
          startDate={this.props.filters.startDate}
          endDate={this.props.filters.endDate}
          quantity={this.props.filters.quantity}
          handleQuantityChange={this.handleQuantityChange}
          handleDatesChange={this.handleDatesChange}
          handleStatusChange={this.handleStatusChange}
          handleCityChange={this.handleCityNameChange}
          handleBuyerChange={this.handleBuyerNameChange}
          handlePriorityChange={this.handlePriorityChange}
          deliveryStatus={this.props.filters.deliveryStatus}
        />
        <PSTableScroll length={items.length} hasMore={hasMore} loadMoreAsync={this.loadMoreAsync}>
          <PSHead checkAll={false}>
            <PSHeadItem>Kupac</PSHeadItem>
            <PSHeadItem>Broj</PSHeadItem>
            <PSHeadItem>Grad</PSHeadItem>
            <PSHeadItem>Adresa</PSHeadItem>
            <PSHeadItem>Datum</PSHeadItem>
            <PSHeadItem>Rok isporuke</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Prioritet</PSHeadItem>
            <PSHeadItem>Cijena</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Isporučeno</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Količina</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Za platiti</PSHeadItem>
            <PSHeadItem appliedCss="text-center">Status</PSHeadItem>
            <PSHeadItem/>
          </PSHead>
          <tbody>
          {
            items.map((i: IOrder) => {
              return (
                <tr className="align-middle white-space-nowrap" key={i.id}>
                  <PSCheckItem checked={false} />
                  <PSDataItem>
                    <Link to="OrderUpsert" onClick={() => this.handleEdit(i.id)}>
                      <h5 className="mb-0 fs--1">{i.buyerName}</h5>
                    </Link>
                  </PSDataItem>
                  <PSDataItem>{i.phoneNumber}</PSDataItem>
                  <PSDataItem containerClassName="fw-semi-bold">{i.city}</PSDataItem>
                  <PSDataItem>{i.address}</PSDataItem>
                  <PSDataItem containerClassName="title-case">
                    {dateFns.format(new Date(i.pelletOrderDate), "PPP", {locale: this.context.currentLocale})}
                  </PSDataItem>
                  <PSDataItem containerClassName="title-case fw-semi-bold">
                    {dateFns.format(new Date(i.pelletDeliveryDate), "PPP", {locale: this.context.currentLocale})}
                  </PSDataItem>
                  <PSDataItem center>
                    {!!i.deliveryPriority ? <span className="fw-black text-warning">Visok</span> : <span className="fw-semi-bold text-muted">-</span>}
                  </PSDataItem>
                  <PSDataItem>{i.price} KM/t</PSDataItem>
                  <PSDataItem center containerClassName="fw-semi-bold">{this.formatDeliveredQuantity(i.deliveredQuantity)}</PSDataItem>
                  <PSDataItem center>{i.quantity} t</PSDataItem>
                  <PSDataItem center containerClassName="fw-semi-bold">
                    <NumberFormat
                      suffix=" KM"
                      value={i.total}
                      thousandSeparator
                      displayType="text"
                      decimalSeparator="."
                      renderText={(value: string) => value}
                    />
                  </PSDataItem>
                  <PSDataItem>
                    <SoftBadge pill={false} className="fs--1 w-100" bg={this.getBackground(i.deliveryStatus)}>{this.getStatus(i.deliveryStatus)}</SoftBadge>
                  </PSDataItem>
                  <PSDataItem containerClassName="text-end"/>
                </tr>
              )
            })
          }
          </tbody>
        </PSTableScroll>
        <PSTableLoader show={this.state.isLoading}/>
        <PSFooter>
          <hr />
          <div className="d-flex pe-4">
            <OrdersCount count={this.props.totalCount}/>
            <div className="d-flex">
              <TotalDemand count={this.props.orderedQuantity}/>
              <TotalDelivery count={this.props.deliveredQuantity}/>
              <RemainingDelivery count={this.props.orderedQuantity - this.props.deliveredQuantity}/>
            </div>
          </div>
        </PSFooter>
      </PSTable>
    );

  }
}


const mapStateToProps = (state: IRootState) => {
  return {
    page: state.order.page,
    items: state.order.items,
    isEdit: state.order.isEdit,
    isBusy: state.order.isBusy,
    filters: state.order.filters,
    hasMore: state.order.hasMore,
    pageSize: state.order.pageSize,
    nextPage: state.order.nextPage,
    showError: state.order.showError,
    totalCount: state.order.totalCount,
    errorMessage: state.order.errorMessage,
    orderedQuantity: state.order.orderedQuantity,
    deliveredQuantity: state.order.deliveredQuantity,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getOrdersAsync, getOrderAsync, resetState, editItem, clearData, setEntityType, setFilters}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(OrdersContainer));