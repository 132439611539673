import * as actions from './Actions';
import { IApplicationProps } from "../Types";

const initialState: IApplicationProps = {
  roles: [],
  fullName: "",
  accessToken: "",
  currentLanguage: "bs",
  isAuthenticated: false,
}

const AppReducer = (state: IApplicationProps = initialState, action: any) => {
  switch (action.type) {
    // Logout & Login
    case  actions.APP_LOGIN:
      return {...state, ...action.payload, isAuthenticated: true}
    case actions.APP_LOGOUT:
      return {...state, ...initialState, currentLanguage: state.currentLanguage};
    default:
      return state;
  }
}

export { AppReducer };