import React from "react";
import { Spinner } from "react-bootstrap";

interface Props {
  show: boolean;
}

export class PSTableLoader extends React.PureComponent<Props> {
  render() {
    if (!this.props.show) return null;
    return (
      <div className="d-flex justify-content-center pt-5 pb-5">
        <Spinner animation="border" size="sm"/>
      </div>
    );
  }
}