import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './PSolutions.Styles/index.css';
import './PSolutions.Startup/InitializeIcons';
import "react-datepicker/dist/react-datepicker.css";
import './PSolutions.Interceptors/HttpInterceptors';
import { store } from "./PSolutions.Store/AdminStore";
import AdminApplication from "./PSolutions.App/Container/AdminApplication";

Sentry.init({
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing()],
  dsn: "https://0faac7905fb5452cb69769cfcbcaf5bf@o1165620.ingest.sentry.io/6255800",
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AdminApplication/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('main')
);

