import * as actions from './Actions'
import * as dateFns from 'date-fns';
import { IProductionProps } from "../Types";

const initialState: IProductionProps = {
  page: 1,
  pageSize: 50,
  nextPage: 1,
  isBusy: false,
  isEdit: false,
  totalCount: 0,
  hasMore: false,
  errorMessage: "",
  showError: false,
  isDeleting: false,
  quantityProduced: 0,
  items: [],
  selectedItem: {
    id: 0,
    quantity: 0,
    description: "",
    date: dateFns.format(new Date(), "yyyy-MM-dd"),
  }
}

const ProductionReducer = (state: IProductionProps = initialState, action: any) => {
  switch (action.type) {
    // GET
    case actions.GET_PRODUCTIONS_REQUESTED:
      return {...state, isBusy: true};
    case actions.GET_PRODUCTIONS_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.GET_PRODUCTIONS_SUCCESS:
      return {...state, isBusy: false, ...action.payload, items: [...state.items, ...action.payload.data]};

    // GET
    case actions.GET_PRODUCTION_REQUESTED:
      return {...state, isBusy: true};
    case actions.GET_PRODUCTION_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.GET_PRODUCTION_SUCCESS:
      return {...state, isBusy: false, selectedItem: {...action.payload}};

    // ADD
    case actions.CREATE_PRODUCTION_REQUESTED:
      return {...state, isBusy: true};
    case actions.CREATE_PRODUCTION_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.CREATE_PRODUCTION_SUCCESS:
      return {...state, ...initialState};

    // UPDATE
    case actions.UPDATE_PRODUCTION_REQUESTED:
      return {...state, isBusy: true};
    case actions.UPDATE_PRODUCTION_FAILURE:
      return {...state, isBusy: false, showError: true, errorMessage: action.payload};
    case actions.UPDATE_PRODUCTION_SUCCESS:
      return {...state, ...initialState};

    // UPDATE
    case actions.DELETE_PRODUCTION_REQUESTED:
      return {...state, isDeleting: true};
    case actions.DELETE_PRODUCTION_FAILURE:
      return {...state, isDeleting: false, showError: true, errorMessage: action.payload};
    case actions.DELETE_PRODUCTION_SUCCESS:
      return {...state, ...initialState};

    // Utilities
    case actions.EDIT_ITEM:
      return {...state, isEdit: true, selectedItem: {...state.selectedItem, id: action.payload}}
    case actions.RESET_STATE:
      return {...state, ...initialState, isEdit: state.isEdit, selectedItem: {...state.selectedItem}};
    case actions.RESET_HARD:
      return {...state, ...initialState};
    default:
      return state;
  }
}

export { ProductionReducer };