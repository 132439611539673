import React from "react";
import * as dateFns from 'date-fns';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../../PSolutions.State";
import { IProduction, IProductionProps } from "../Types";
import { PSTableLoader } from "../../../PSolutions.UI/Table/Loader";
import { PSHead } from "../../../PSolutions.UI/Table/Header/PSHead";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { PSTable } from "../../../PSolutions.UI/Table/Holder/PSTable";
import { PSHeader } from "../../../PSolutions.UI/Table/Header/PSHeader";
import { PSButton } from "../../../PSolutions.UI/Table/Items/PSButton";
import { PSFooter } from "../../../PSolutions.UI/Table/Footer/PSFooter";
import { PSHeadItem } from "../../../PSolutions.UI/Table/Items/PSHeadItem";
import { PSCheckItem } from "../../../PSolutions.UI/Table/Items/PSCheckItem";
import { PSDataItem } from "../../../PSolutions.UI/Table/Items/PSDataItem";
import { editItem, getProductionsAsync, resetState } from "../Redux/Actions";
import { PSTableScroll } from "../../../PSolutions.UI/Table/Holder/PSTableScroll";
import { TranslationContext } from "../../../PSolutions.Providers/Translation/TranslationContext";

interface Props extends IProductionProps {
  resetState(): void;

  editItem(id: number): void;

  navigate(to: string): void;

  getProductionsAsync(page: number, pageSize: number): any;
}

class ProductionsContainer extends React.PureComponent<Props> {
  static contextType = TranslationContext;
  context!: React.ContextType<typeof TranslationContext>;

  constructor(props: Props) {
    super(props);
    this.handleNew = this.handleNew.bind(this);
    this.loadMoreAsync = this.loadMoreAsync.bind(this);
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  async componentDidMount() {
    await this.props.getProductionsAsync(this.props.page, this.props.pageSize);
  }

  async loadMoreAsync() {
    await this.props.getProductionsAsync(this.props.nextPage, this.props.pageSize);
  }

  handleNew() {
    this.props.navigate("ProductionUpsert")
  }

  render() {
    const {hasMore, isBusy} = this.props;
    const items = this.props.items || [];

    return (
      <PSTable>
        <PSHeader title="Proizvodnja">
          <PSButton title="Dodaj" icon="plus" onClick={this.handleNew}/>
        </PSHeader>
        <PSTableScroll length={items.length} hasMore={hasMore} loadMoreAsync={this.loadMoreAsync}>
          <PSHead checkAll={false}>
            <PSHeadItem>Količina</PSHeadItem>
            <PSHeadItem>Opis</PSHeadItem>
            <PSHeadItem>Datum</PSHeadItem>
            <PSHeadItem/>
          </PSHead>
          <tbody>
          {
            items.map((i: IProduction) => {
              return (
                <tr className="align-middle white-space-nowrap" key={i.id}>
                  <PSCheckItem checked={false}/>
                  <PSDataItem>
                    <Link to="ProductionUpsert" onClick={() => this.props.editItem(i.id)}>
                      <h5 className="mb-0 fs--1">
                        <NumberFormat
                          suffix=" tona"
                          thousandSeparator
                          displayType="text"
                          value={i.quantity}
                          decimalSeparator="."
                          renderText={(value: string) => value}
                        />
                      </h5>
                    </Link>
                  </PSDataItem>
                  <PSDataItem>{i.description}</PSDataItem>
                  <PSDataItem containerClassName="title-case">
                    {dateFns.format(new Date(i.date), "PPP", {locale: this.context.currentLocale})}
                  </PSDataItem>
                  <PSDataItem containerClassName="text-end"/>
                </tr>
              )
            })
          }
          </tbody>
        </PSTableScroll>
        <PSTableLoader show={isBusy}/>
        <PSFooter>
          <div className="d-flex">
            <NumberFormat
              suffix=" tona"
              thousandSeparator
              displayType="text"
              decimalSeparator="."
              value={this.props.quantityProduced}
              renderText={(value: string) => <h5 className="fs--1">Ukupna proizvodnja:&nbsp;&nbsp;{value}</h5>}
            />
          </div>
        </PSFooter>
      </PSTable>
    );
  }
}


const mapStateToProps = (state: IRootState) => {
  return {
    page: state.production.page,
    items: state.production.items,
    isEdit: state.production.isEdit,
    isBusy: state.production.isBusy,
    hasMore: state.production.hasMore,
    pageSize: state.production.pageSize,
    nextPage: state.production.nextPage,
    showError: state.production.showError,
    errorMessage: state.production.errorMessage,
    quantityProduced: state.production.quantityProduced,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getProductionsAsync, resetState, editItem}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ProductionsContainer));