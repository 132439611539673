import axios from 'axios';
import { Dispatch } from "redux";
import { IFilters, IOrder, IOrderQuery } from "../Types";
import { IList } from "../../../PSolutions.Interfaces/Common";

export const GET_ORDERS_SUCCESS = "Order/GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "Order/GET_ORDERS_FAILURE";
export const GET_ORDERS_REQUESTED = "Order/GET_ORDERS_REQUESTED";

export const GET_ORDER_SUCCESS = "Order/GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "Order/GET_ORDER_FAILURE";
export const GET_ORDER_REQUESTED = "Order/GET_ORDER_REQUESTED";

export const DELETE_ORDERS_SUCCESS = "Order/DELETE_ORDERS_SUCCESS";
export const DELETE_ORDERS_FAILURE = "Order/DELETE_ORDERS_FAILURE";
export const DELETE_ORDERS_REQUESTED = "Order/DELETE_ORDERS_REQUESTED";

export const UPDATE_ORDERS_SUCCESS = "Order/UPDATE_ORDERS_SUCCESS";
export const UPDATE_ORDERS_FAILURE = "Order/UPDATE_ORDERS_FAILURE";
export const UPDATE_ORDERS_REQUESTED = "Order/UPDATE_ORDERS_REQUESTED";

export const CREATE_ORDERS_SUCCESS = "Order/CREATE_ORDERS_SUCCESS";
export const CREATE_ORDERS_FAILURE = "Order/CREATE_ORDERS_FAILURE";
export const CREATE_ORDERS_REQUESTED = "Order/CREATE_ORDERS_REQUESTED";

export const SET_FILTERS = "Order/SET_FILTERS"
export const setFilters = (filters: IFilters) => (dispatch: Dispatch) => dispatch({type: SET_FILTERS, payload: filters});

export const SET_ENTITY_TYPE = "Order/SET_ENTITY_TYPE"
export const setEntityType = (entityType: number) => (dispatch: Dispatch) => dispatch({type: SET_ENTITY_TYPE, payload: entityType});

export const RESET_HARD = "Order/RESET_HARD";
export const resetHard = () => (dispatch: Dispatch) => dispatch({type: RESET_HARD});

export const CLEAR_DATA = "Order/CLEAR_DATA";
export const clearData = () => (dispatch: Dispatch) => dispatch({type: CLEAR_DATA});

export const RESET_STATE = "Order/RESET_STATE";
export const resetState = () => (dispatch: Dispatch) => dispatch({type: RESET_STATE});

export const EDIT_ITEM = "Order/EDIT_ITEM"
export const editItem = (id: number) => (dispatch: Dispatch) => dispatch({type: EDIT_ITEM, payload: id});

export const getOrdersAsync = (query: IOrderQuery) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_ORDERS_REQUESTED});
      const response = await axios.get<IList<IOrder>>("Orders", {params: query});
      dispatch({type: GET_ORDERS_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_ORDERS_FAILURE});
      return null;
    }
  }
};

export const getOrderAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_ORDER_REQUESTED});
      const response = await axios.get<IOrder>(`Orders/${id}`);
      dispatch({type: GET_ORDER_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_ORDER_FAILURE});
      return null;
    }
  }
};

export const deleteOrderAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: DELETE_ORDERS_REQUESTED});
      const response = await axios.delete(`Orders/${id}`);
      dispatch({type: DELETE_ORDERS_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: DELETE_ORDERS_FAILURE});
      return null;
    }
  }
};

export const createOrderAsync = (item: IOrder) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: CREATE_ORDERS_REQUESTED});
      await axios.post("Orders", item);
      dispatch({type: CREATE_ORDERS_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: CREATE_ORDERS_FAILURE});
      return false;
    }
  }
};

export const updateOrderAsync = (item: IOrder) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_ORDERS_REQUESTED});
      await axios.put("Orders", item);
      dispatch({type: UPDATE_ORDERS_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: UPDATE_ORDERS_FAILURE});
      return false;
    }
  }
};

