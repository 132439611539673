import React from "react";
import { Card } from "react-bootstrap";

interface Props {
  title: string;
}

export class PSFormHeader extends React.PureComponent<Props> {
  render() {
    return (
      <Card.Header>
        <h5>{this.props.title}</h5>
      </Card.Header>
    );
  }
}