export const navbarBreakPoint = 'xl';
export const topNavbarBreakpoint = 'lg';

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};