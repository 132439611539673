import React from "react";
import { Navbar } from "react-bootstrap";
import { NavContent } from "./Content/NavContent";
import { NavHeader } from "./Header/NavHeader";
import { NavSeparator } from "./Items/NavSeparator";
import { NavigationItem } from "./Items/NavigationItem";
import { RolesEnum } from "../../PSolutions.Enum/Roles";
import { RoleGuard } from "../../PSolutions.Guard/RoleGuard";
import { navbarBreakPoint } from "../../PSolutions.Config/UserInterfaceConfig";

interface Props {
  expanded: boolean;

  onItemClick(): void;
}

export class SidebarNavigation extends React.PureComponent<Props> {
  render() {
    const {expanded} = this.props;
    return (
      <Navbar className="navbar-vertical" variant="light" expand={navbarBreakPoint} expanded={expanded}>
        <NavHeader/>
        <NavContent>
          <NavSeparator label="Narudžbe"/>
          <NavigationItem icon="users" label="Fizička lice" navigateTo="Orders" onClick={this.props.onItemClick}/>
          <NavigationItem icon="building" label="Pravna lica" navigateTo="LegalOrders" onClick={this.props.onItemClick}/>
          <NavSeparator label="Osoblje"/>
          <RoleGuard role={RolesEnum.Admin}>
            <NavigationItem icon="user" label="Zaposlenici" navigateTo="Employees" onClick={this.props.onItemClick}/>
          </RoleGuard>
          <NavSeparator label="Skladište"/>
          <NavigationItem icon="industry" label="Proizvodnja" navigateTo="Production" onClick={this.props.onItemClick}/>
        </NavContent>
      </Navbar>
    );
  }
}