import { combineReducers } from "redux";
import { AppReducer } from "../PSolutions.App/Redux/Reducer";
import { OrderReducer } from "../PSolutions.Container/Orders/Redux/Reducer";
import { EmployeesReducer } from '../PSolutions.Container/Employees/Redux/Reducer';
import { ProductionReducer } from "../PSolutions.Container/Production/Redux/Reducer";

const RootReducer = combineReducers({
  app: AppReducer,
  order: OrderReducer,
  employee: EmployeesReducer,
  production: ProductionReducer,
});

export type IRootState = ReturnType<typeof RootReducer>;

export { RootReducer }