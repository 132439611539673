import React from "react";
import NumberFormat from "react-number-format";

interface Props {
  count: number;
}

export class RemainingDelivery extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderText = this.renderText.bind(this);
  }

  renderText(value: string) {
    return <h5 className="fs--1">Preostalo:&nbsp;&nbsp;<span className="text-warning">{value}</span></h5>
  }

  render() {
    const {count} = this.props;
    return (
      <NumberFormat
        value={count}
        suffix=" tona"
        decimalScale={2}
        thousandSeparator
        displayType="text"
        decimalSeparator="."
        renderText={this.renderText}
      />
    );
  }
}