import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from 'formik';
import { bindActionCreators, Dispatch } from "redux";
import { OrderSchema } from "./OrderSchema";
import { IOrder, IOrderProps } from "../Types";
import { IRootState } from "../../../PSolutions.State";
import { OrderUpsertView } from "../Views/OrderUpsertView";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { createOrderAsync, getOrderAsync, resetHard, updateOrderAsync } from "../Redux/Actions";

interface Props extends IOrderProps {
  navigate(to: any): void;

  resetHard(): void;

  getOrderAsync(id: number): any;

  updateOrderAsync(app: IOrder): any;

  createOrderAsync(app: IOrder): any;
}

class OrdersUpsertContainer extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.submitAsync = this.submitAsync.bind(this);
    this.createApplicationAsync = this.createApplicationAsync.bind(this);
    this.updateApplicationAsync = this.updateApplicationAsync.bind(this);
  }

  componentWillUnmount() {
    this.props.resetHard();
  }

  async componentDidMount() {
    if (this.props.selectedItem.id === 0) return;
    await this.props.getOrderAsync(this.props.selectedItem.id);
  }

  // Submit async
  async submitAsync(values: IOrder, actions: FormikHelpers<any>) {
    if (this.props.isEdit) await this.updateApplicationAsync(values, actions);
    else await this.createApplicationAsync(values, actions);
  }

  // Create application
  async createApplicationAsync(order: IOrder, actions: FormikHelpers<any>) {
    const res = await this.props.createOrderAsync(order);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(-1);
  }

  // Update application
  async updateApplicationAsync(employee: IOrder, actions: FormikHelpers<any>) {
    const res = await this.props.updateOrderAsync(employee);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(-1);
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.submitAsync}
        component={OrderUpsertView}
        validationSchema={OrderSchema}
        initialValues={this.props.selectedItem}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isEdit: state.order.isEdit,
    isBusy: state.order.isBusy,
    showError: state.order.showError,
    errorMessage: state.order.errorMessage,
    selectedItem: state.order.selectedItem,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getOrderAsync, updateOrderAsync, createOrderAsync, resetHard}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(OrdersUpsertContainer));
