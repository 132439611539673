import React from "react";
import { Card, Col } from "react-bootstrap";
import { PSFormHeader } from "./PSFormHeader";

interface Props {
  title: string;
  columns: number
  className?: string;
}

export class PSFormCard extends React.PureComponent<Props> {
  static defaultProps = {columns: 12}
  render() {
    return (
      <Col lg={this.props.columns}>
        <Card className={this.props.className}>
          <PSFormHeader title={this.props.title}/>
          <Card.Body className="bg-light">{this.props.children}</Card.Body>
        </Card>
      </Col>
    );
  }
}