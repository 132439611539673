import React from "react";
import NumberFormat from "react-number-format";

interface Props {
  count: number;
}

export class OrdersCount extends React.PureComponent<Props> {
  render() {
    const {count} = this.props;
    return <NumberFormat thousandSeparator displayType="text" decimalSeparator="." value={count} renderText={(value: string) => <h5 className="fs--1 pe-3">Broj narudžbi:&nbsp;&nbsp;{value}</h5>}/>;
  }
}