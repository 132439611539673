import React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers } from 'formik';
import { bindActionCreators, Dispatch } from "redux";
import { IRootState } from "../../../PSolutions.State";
import { IProduction, IProductionProps } from "../Types";
import { withNavigation } from "../../../PSolutions.Hoc/Navigation";
import { ProductionUpsertView } from "../Views/ProductionUpsertView";
import { createProductionAsync, getProductionAsync, resetHard, updateProductionAsync } from "../Redux/Actions";

interface Props extends IProductionProps {
  resetHard(): void;

  navigate(to: any): void;

  getProductionAsync(id: number): any;

  updateProductionAsync(app: IProduction): any;

  createProductionAsync(app: IProduction): any;
}

class ProductionUpsertContainer extends React.PureComponent<Props> {
  private readonly Url = "/Production";

  constructor(props: Props) {
    super(props);
    this.submitAsync = this.submitAsync.bind(this);
    this.createApplicationAsync = this.createApplicationAsync.bind(this);
    this.updateApplicationAsync = this.updateApplicationAsync.bind(this);
  }

  componentWillUnmount() {
    this.props.resetHard();
  }

  async componentDidMount() {
    if (this.props.selectedItem.id === 0) return;
    await this.props.getProductionAsync(this.props.selectedItem.id);
  }

  // Submit async
  async submitAsync(values: IProduction, actions: FormikHelpers<any>) {
    if (this.props.isEdit) await this.updateApplicationAsync(values, actions);
    else await this.createApplicationAsync(values, actions);
  }

  // Create application
  async createApplicationAsync(item: IProduction, actions: FormikHelpers<any>) {
    const res = await this.props.createProductionAsync(item);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(this.Url);
  }

  // Update application
  async updateApplicationAsync(item: IProduction, actions: FormikHelpers<any>) {
    const res = await this.props.updateProductionAsync(item);
    actions.setSubmitting(false);
    if (!!res) this.props.navigate(this.Url);
  }

  render() {
    const values = {...this.props.selectedItem};
    return (
      <Formik
        initialValues={values}
        enableReinitialize={true}
        onSubmit={this.submitAsync}
        component={ProductionUpsertView}
      />
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    isEdit: state.production.isEdit,
    isBusy: state.production.isBusy,
    showError: state.production.showError,
    errorMessage: state.production.errorMessage,
    selectedItem: state.production.selectedItem,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({getProductionAsync, updateProductionAsync, createProductionAsync, resetHard}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ProductionUpsertContainer));
