import axios from 'axios';
import { Dispatch } from "redux";
import { IProduction } from "../Types";
import { IList } from "../../../PSolutions.Interfaces/Common";

export const GET_PRODUCTION_SUCCESS = "Production/GET_PRODUCTION_SUCCESS";
export const GET_PRODUCTION_FAILURE = "Production/GET_PRODUCTION_FAILURE";
export const GET_PRODUCTION_REQUESTED = "Production/GET_PRODUCTION_REQUESTED";

export const GET_PRODUCTIONS_SUCCESS = "Production/GET_PRODUCTIONS_SUCCESS";
export const GET_PRODUCTIONS_FAILURE = "Production/GET_PRODUCTIONS_FAILURE";
export const GET_PRODUCTIONS_REQUESTED = "Production/GET_PRODUCTIONS_REQUESTED";

export const DELETE_PRODUCTION_SUCCESS = "Production/DELETE_PRODUCTION_SUCCESS";
export const DELETE_PRODUCTION_FAILURE = "Production/DELETE_PRODUCTION_FAILURE";
export const DELETE_PRODUCTION_REQUESTED = "Production/DELETE_PRODUCTION_REQUESTED";

export const UPDATE_PRODUCTION_SUCCESS = "Production/UPDATE_PRODUCTION_SUCCESS";
export const UPDATE_PRODUCTION_FAILURE = "Production/UPDATE_PRODUCTION_FAILURE";
export const UPDATE_PRODUCTION_REQUESTED = "Production/UPDATE_PRODUCTION_REQUESTED";

export const CREATE_PRODUCTION_SUCCESS = "Production/CREATE_PRODUCTION_SUCCESS";
export const CREATE_PRODUCTION_FAILURE = "Production/CREATE_PRODUCTION_FAILURE";
export const CREATE_PRODUCTION_REQUESTED = "Production/CREATE_PRODUCTION_REQUESTED";

export const RESET_HARD = "Production/RESET_HARD";
export const resetHard = () => (dispatch: Dispatch) => dispatch({type: RESET_HARD});

export const RESET_STATE = "Production/RESET_STATE";
export const resetState = () => (dispatch: Dispatch) => dispatch({type: RESET_STATE});

export const EDIT_ITEM = "Production/EDIT_ITEM"
export const editItem = (id: number) => (dispatch: Dispatch) => dispatch({type: EDIT_ITEM, payload: id});

export const getProductionsAsync = (page: number, pageSize: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PRODUCTIONS_REQUESTED});
      const response = await axios.get<IList<IProduction>>("Production", {params: {page, pageSize}});
      dispatch({type: GET_PRODUCTIONS_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_PRODUCTIONS_FAILURE});
      return null;
    }
  }
};

export const getProductionAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PRODUCTION_REQUESTED});
      const response = await axios.get<IProduction>(`Production/${id}`);
      dispatch({type: GET_PRODUCTION_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: GET_PRODUCTION_FAILURE});
      return null;
    }
  }
};

export const deleteProductionAsync = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: DELETE_PRODUCTION_REQUESTED});
      const response = await axios.delete(`Production/${id}`);
      dispatch({type: DELETE_PRODUCTION_SUCCESS, payload: response.data});
      return response.data;
    } catch (e) {
      dispatch({type: DELETE_PRODUCTION_FAILURE});
      return null;
    }
  }
};

export const createProductionAsync = (item: IProduction) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: CREATE_PRODUCTION_REQUESTED});
      await axios.post("Production", item);
      dispatch({type: CREATE_PRODUCTION_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: CREATE_PRODUCTION_FAILURE});
      return false;
    }
  }
};

export const updateProductionAsync = (item: IProduction) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PRODUCTION_REQUESTED});
      await axios.put("Production", item);
      dispatch({type: UPDATE_PRODUCTION_SUCCESS});
      return true;
    } catch (e) {
      dispatch({type: UPDATE_PRODUCTION_FAILURE});
      return false;
    }
  }
};

