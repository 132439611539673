let API_URL = "";

const APPLICATION_DEFAULT_LOCALE = "hr";

const local = "https://localhost:7226/api"
const production = "https://nansi-api.azurewebsites.net/api";

if (process.env.NODE_ENV === "development") API_URL = local;
if (process.env.NODE_ENV === "production") API_URL = production;


export { API_URL, APPLICATION_DEFAULT_LOCALE };
